
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'

export default defineComponent({
  props: {
    transferStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const data = reactive({
      activeList: {
        active10: ['提交资料', '待初审', '初审退回'], // 初审退回
        active9: ['提交资料', '待初审'], // 待初审
        active1: ['提交资料', '待初审', '待申请转移'], // 待申请转移
        active3: ['提交资料', '待初审', '待申请转移', '已申请待交管审核'], // 已申请待转移
        active4: [
          '提交资料',
          '待初审',
          '待申请转移',
          '已申请待交管审核',
          '交管审核退回',
        ], // 审核失败
        active6: [
          '提交资料',
          '待初审',
          '待申请转移',
          '已申请待交管审核',
          '拟将人车违法分离',
        ], // 拟将作出人车分离
        active5: [
          '提交资料',
          '待初审',
          '待申请转移',
          '已申请待交管审核',
          '拟将人车违法分离',
          '完成违法分离',
        ], // 完成违法分离
      },
      optList: ref<any>([
        {
          title: '提交资料',
        },
        {
          title: '待初审',
        },
        {
          title: '初审退回',
        },
        {
          title: '待申请转移',
        },
        {
          title: '已申请待交管审核',
        },
        {
          title: '交管审核退回',
        },
        {
          title: '拟将人车违法分离',
        },
        {
          title: '完成违法分离',
        },
      ]),
    })

    // const actArr = data.activeList[`active${props.transferStatus}`]
    // data.optList.forEach((item) => {
    //   if (actArr.indexOf(item.title) !== -1) {
    //     item.active = true
    //   }
    // })
    //  定义一个函数来更新 optList 的 active 状态
    const updateOptListActive = (status: number) => {
      const actArr = data.activeList[`active${status}`] || [] //  添加默认值，防止 status 不在 activeList 中时报错
      data.optList.forEach((item) => {
        item.active = actArr.includes(item.title)
      })
    }

    //  初始执行一次，根据 props.transferStatus 初始化 optList
    updateOptListActive(props.transferStatus)

    //  使用 watch 监听 props.transferStatus 的变化
    watch(
      () => props.transferStatus, //  监听的是 props.transferStatus
      (newStatus) => {
        updateOptListActive(newStatus) //  当 transferStatus 变化时，调用 updateOptListActive 更新 optList
      }
    )

    return {
      ...toRefs(data),
    }
  },
})
