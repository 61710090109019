
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { toResult } from '@/utils/toResult'
import { checkSubmitMaterialAPI } from '../api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { RangeLimit, required } from '@/utils/rules'

export default defineComponent({
  components: {},
  props: {
    modelValue: Boolean,
    contractJpg: String,
    row: Object,
  },
  setup(props, { emit }) {
    const data = reactive({
      visible: props.modelValue,
      formRef: null,
      formData: ref<any>({
        sendMsg: true,
      }),
      loading: false,
      rules: {
        backReason: [required, RangeLimit(2, 64)],
      },
    })

    const onCancel = () => {
      emit('update:modelValue', false)
    }

    // 保存
    const onSubmit = () => {
      data.formRef.validate(async (valid) => {
        if (valid) {
          ElMessageBox.confirm('确定退回审核吗？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const p: any = {
              operateLogId: props.row.id,
              contractSubNo: props.row.contractSubNo,
              checkStatus: 2,
              backReason: data.formData.backReason,
              sendMsg: data.formData.sendMsg,
            }
            data.loading = true
            const [err] = await toResult(checkSubmitMaterialAPI(p))
            data.loading = false
            if (err) return
            onCancel()
            ElMessage.success('操作成功')
            emit('getListFn')
          })
        }
      })
    }

    onMounted(() => {})

    return {
      ...toRefs(data),
      onCancel,
      onSubmit,
    }
  },
})
