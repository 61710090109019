
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getTableConfig, getTableConfig1, getTableConfig2 } from './config'
import CImgView from '@/components/c-img-view'
import ToExamine from './to-examine.vue'
import Steps from './steps.vue'
import { contractStatus, submitStatus, transferStatus } from '../config'
import { toResult } from '@/utils/toResult'
import {
  checkSubmitMaterialAPI,
  subInfoAPI,
  violationContractOperateLogListAPI,
} from '../api'
import moment from 'moment'
import { hiddenIdCard } from '@/utils'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  components: { CTable, CImgView, ToExamine, Steps },
  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig(),
    })
    const router = useRouter()

    const data = reactive({
      contractStatus,
      submitStatus,
      transferStatus,
      id: router.currentRoute.value.query.id,
      loading: false,
      dialog: false,
      remark: '',
      contractJpg: '', // 合同图片
      formData: ref<any>({}),
      row: null,
      isHide: true, // 身份证号 隐藏显示
      tableConfig1: getTableConfig1(router.currentRoute.value.query.id), // 违章转移列表
      tableConfig2: getTableConfig2(), // 申请记录列表
      isToExamine: false, // 退回审核
      isYHTXY: false, // 源合同 展开/收起
      isWZZYLB: false, // 转移列表 展开/收起
    })

    const handleGoBack = () => {
      router.back()
    }

    // 合同操作记录
    const getContractOperateLogFn = () => {
      data.tableConfig2.loading = true
      violationContractOperateLogListAPI({
        contractSubNo: data.formData.violationContractSubVo.contractSubNo,
      })
        .then((res) => {
          data.tableConfig2.data = res.data || []
        })
        .finally(() => {
          data.tableConfig2.loading = false
        })
    }

    // 列表数据
    const getListFn = async () => {
      data.loading = true
      data.contractJpg = ''
      const [err, res] = await toResult(subInfoAPI({ id: data.id }))
      data.loading = false
      if (err) return
      data.formData = res
      tableConfig.data = res.violationVos || [] // 期间车辆违章信息
      data.tableConfig1.data = res.detailSubVos || [] // 违章转移列表
      data.contractJpg = res.violationContractVo.contractJpg || ''
      data.remark = res.violationContractVo.remark || ''
      getContractOperateLogFn()
    }

    // 新增/编辑 附件合同
    const editFile = () => {
      data.dialog = true
      data.row = data.id
    }

    // 审核通过 / 审核退回
    const handleAction = (type, row) => {
      switch (type) {
        case '审核通过': {
          ElMessageBox.confirm('确定审核通过吗？', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const [err] = await toResult(
              checkSubmitMaterialAPI({
                operateLogId: row.id,
                contractSubNo: row.contractSubNo,
                checkStatus: 1,
              })
            )
            if (err) return
            ElMessage.success('操作成功')
            getListFn()
          })
          break
        }
        case '审核退回': {
          data.row = row
          data.isToExamine = true
          break
        }
      }
    }

    onMounted(() => {
      getListFn()
    })

    return {
      tableConfig,
      ...toRefs(data),
      moment,
      getListFn,
      handleAction,
      handleGoBack,
      editFile,
      hiddenIdCard,
      getContractOperateLogFn,
    }
  },
})
