import moment from 'moment'
import { contractStatus, submitStatus } from '../config'
import { isMobile } from '@/utils'

// 违章处理状态
export enum status {
  未处理 = 1,
  处理中,
  已处理,
  已撤销,
  默认已处理,
  未处理已人车分离,
}

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'happenTime',
      label: '违章时间',
      width: '100'
    },
    {
      key: 'place',
      label: '违章地点',
      width: '150'
    },
    {
      key: 'reason',
      label: '违章行为',
      width: '250'
    },
    {
      key: 'score',
      label: '扣分',
      width: '80'
    },
    {
      key: 'fine',
      label: '罚款',
      width: '80'
    },
    {
      key: 'dealDeadlineTime',
      label: '提示处理截止日期',
      width: '120'
    },
    {
      key: 'status',
      label: '处理状态',
      width: '100',
      formatter: (row, col, val) => status[val]
    },
    {
      key: 'sendStatus',
      label: '提醒短信',
      width: '100',
      slot: 'sendStatus'
    },
    {
      key: 'sendTime',
      label: '发送时间',
      width: '100'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

export const getTableConfig1 = (id) => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // 设置表行颜色
    tableRowClassName: ({ row }) => {
      if (Number(row.id) === Number(id)) {
        return 'hihgh-light'
      }
    },
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1
  }

  // table 列数据
  const column = [
    {
      key: 'contractRecordNo',
      label: '合同备案编号',
      width: '100'
    },
    {
      key: 'contractStartTime',
      label: '合同开始日期',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'contractEndTime',
      label: '合同到期日期',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'contractStatus',
      label: '备案状态',
      width: '100',
      formatter: (row, col, val) => contractStatus[val]
    },
    {
      key: 'terminalTime',
      label: '备案终止日期',
      width: '100',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'submitStatus',
      label: '提交状态',
      width: '100',
      formatter: (row, col, val) => submitStatus[val]
    },
    {
      key: 'submitTime',
      label: '提交时间',
      width: '100',
      formatter: (row, col, val) => val || '-'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

export const getTableConfig2 = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'operateType',
      label: '类型',
      width: '100',
      formatter: (row, col, val) => val === 1 ? '调整合同' : '申请资料'
    },
    {
      slot: 'petition',
      label: '申请材料',
      width: '100'
    },
    {
      slot: 'contractPdf',
      label: 'PDF文件',
      width: '100'
    },
    {
      key: 'submitTime',
      label: '提交时间',
      width: '100'
    },
    {
      key: 'updateTime',
      label: '更新时间',
      width: '100'
    },
    {
      key: 'checkTime',
      label: '初审核时间',
      width: '100',
      formatter: (row, col, val) => val ? moment(val).format('YYYY-MM-DD HH:mm') : '-',
      slot: 'checkTime'
    },
    {
      key: 'action',
      label: '操作',
      width: isMobile() ? '160' : '130',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
